import { $themeColors } from "@themeConfig";
// colors
const chartColors = {
    primaryColorShade: "#836AF9",
    yellowColor: "#ffe800",
    successColorShade: "#28dac6",
    warningColorShade: "#ffe802",
    warningLightColor: "#FDAC34",
    infoColorShade: "#299AFF",
    greyColor: "#4F5D70",
    blueColor: "#0c102a",
    blueLightColor: "#84D0FF",
    greyLightColor: "#EDF1F4",
    tooltipShadow: "rgba(0, 0, 0, 0.25)",
    lineChartPrimary: "#666ee8",
    lineChartDanger: "#ff4961",
    labelColor: "#6e6b7b",
    grid_line_color: "rgba(200, 200, 200, 0.2)",
    blueColorH: "#5f95e7",
    blueColorD: "rgba(0, 0, 0, 0.25)",
    blueColorW: "#4F5D70",
    blueColorG: "rgba(200, 200, 200, 0.2)",
    /*
    pcAltum1: "#031133",
    pcAltum2: "#04619B",
    pcAltum3: "#179BDB",
    pcAltum4: "#80BBE7",
    pcAltum5: "#A5D7EF",
    pcAltum6: "#C1E6F4"
    */


    pcAltum7: "#F0AE33",
    pcAltum8: "#9CACB1",
    pcAltum4: "#0058A0",
    pcAltum1: "#B9CDE5",
    pcAltum5: "#E26629",
    pcAltum9: "#0058A0",
    pcAltum6: "#003D7E",

    pcAltum2: "#E46C0A",
    pcAltum3: "#1F497D",




};

export default {
    ChartDashBoardMuestreo: {
        data: {
            labels: [
                "1000",
                "2000",
                "3000",
                "4000",
                "5000",
                "6000",
                "7000"
            ],

            datasets: [{
                type: 'bar',
                label: 'Bar Dataset',
                borderColor: "transparent",
                backgroundColor: chartColors.pcAltum4,
                data: [0.5, 0.6, 4, 5, 6, 5.5, 7]
            }, {
                type: 'line',
                fill: false,
                label: 'Line Dataset',
                pointRadius: 0,
                backgroundColor: chartColors.pcAltum4,
                data: [0.8, 0.9, 5, 7, 7, 6, 8],
            }],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
    },
    operationalBarChart: {
        data: {
            labels: [
                "51",
                "52",
                "53",
                "54",
                "55",
                "56",
                "57",
                "58",
                "59",
                "510"
            ],
            datasets: [{
                data: [275, 90, 190, 205, 125, 85, 55, 1, 4, 7],
                backgroundColor: chartColors.pcAltum1,
                borderColor: "transparent"
            }]
        },
        options: {
            elements: {
                rectangle: {
                    borderWidth: 2,
                    borderSkipped: "bottom"
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
                display: false
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            },
            scales: {
                xAxes: [{
                    display: true,
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    scaleLabel: {
                        display: false
                    },
                    ticks: {
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    display: true,
                    gridLines: {
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        stepSize: 100,
                        min: 0,
                        max: 400,
                        fontColor: chartColors.labelColor
                    }
                }]
            }
        }
    },
    latestBarChart: {
        data: {
            labels: [
                "Lunes",
                "Martes",
                "Miercoles",
                "Jueves",
                "Viernes",
                "Sabado",
                "Domingo"
            ],
            datasets: [{
                data: [275, 90, 190, 205, 125, 85, 55],
                backgroundColor: chartColors.blueColorH,
                borderColor: "transparent"
            }]
        },
        options: {
            elements: {
                rectangle: {
                    borderWidth: 2,
                    borderSkipped: "bottom"
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
                display: false
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            },
            scales: {
                xAxes: [{
                    display: true,
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    scaleLabel: {
                        display: false
                    },
                    ticks: {
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    display: true,
                    gridLines: {
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        stepSize: 100,
                        min: 0,
                        max: 400,
                        fontColor: chartColors.labelColor
                    }
                }]
            }
        }
    },
    horizontalBarChart: {
        options: {
            elements: {
                rectangle: {
                    borderWidth: 2,
                    borderSkipped: "top"
                }
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            },
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    display: true,
                    gridLines: {
                        zeroLineColor: chartColors.grid_line_color,
                        borderColor: "transparent",
                        color: chartColors.grid_line_color,
                        drawTicks: false
                    },
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        min: 0,
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    display: true,
                    gridLines: {
                        display: false
                    },
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        fontColor: chartColors.labelColor
                    }
                }]
            }
        },
        data: {
            labels: ["MON", "TUE", "WED ", "THU", "FRI", "SAT", "SUN"],
            datasets: [{
                data: [710, 350, 470, 580, 230, 460, 120],
                backgroundColor: $themeColors.info,
                borderColor: "transparent",
                barThickness: 15
            }]
        }
    },
    lineChartDetailPlanning: {
        options: {
            options: {
                responsive: true,
                maintainAspectRatio: false,
                backgroundColor: false,
                hover: {
                  mode: 'label',
                },
                tooltips: {
                  shadowColor: chartColors.greyLightColor,
                  backgroundColor: "#ffffff",
                  titleFontColor: "#000000",
                  bodyFontColor: "#000000",
                  borderColor: 'rgba(0, 0, 0, 0.3)',
                  borderWidth: 1,
                  padding: 8
                },
                layout: {
                  padding: {
                    top: -15,
                    bottom: -25,
                    left: -15,
                  },
                },
                scales: {
                  xAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: true,
                      },
                      gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color,
                      },
                      ticks: {
                        fontColor: chartColors.labelColor,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      display: true,
                      scaleLabel: {
                        display: true,
                      },
                      ticks: {
                        stepSize: 0.5,
                        min: 0,
                        max: 7,
                        fontColor: chartColors.labelColor,
                      },
                      gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color,
                      },
                    },
                  ],
                },
                legend: {
                  position: 'top',
                  align: 'start',
                  labels: {
                    usePointStyle: true,
                    padding: 25,
                    boxWidth: 9,
                  },
                },
            },
        },
        data: {
            labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "120", "130", "140"],
            datasets: [{
                data: [1000, 1150, 1700, 2900, 2000, 1600, 1500, 1100, 2000, 2900, 2500, 2300, 3500, 3650, 3900],
                label: "Tomé",
                borderColor: chartColors.pcAltum4,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.pcAltum4,
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.pcAltum4,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [1000, 1350, 1900, 3200, 2200, 1900, 1800, 1300, 2250, 3400, 2800, 2550, 3750],
                label: "San José",
                borderColor: chartColors.pcAltum1,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.pcAltum1,
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.pcAltum1,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [1000, 1200, 2200, 3250, 1060, 1850, 1450, 2300, 3550, 2600, 4000],
                label: "Sur Proceso",
                borderColor: chartColors.pcAltum8,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.pcAltum8,
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.pcAltum8,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [1010, 1300, 2300, 3500, 1160, 1950, 1550, 2350, 3650, 2710, 4200],
                label: "Externo",
                borderColor: chartColors.pcAltum5,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.pcAltum5,
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.pcAltum5,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            }
            ]
        }
    },
    lineChartML: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            backgroundColor: false,
            hover: {
                mode: "label"
            },
            tooltips: {
                // Updated default tooltip UI
                shadowColor: chartColors.greyLightColor,
                backgroundColor: "#ffffff",
                titleFontColor: "#000000",
                bodyFontColor: "#000000",
                borderColor: 'rgba(0, 0, 0, 0.3)',
                borderWidth: 1,
                padding: 8
                // shadowOffsetX: 1,
                // shadowOffsetY: 1,
                // shadowBlur: 8,
                // shadowColor: chartColors.tooltipShadow,
                // backgroundColor: $themeColors.white,
                // titleFontColor: $themeColors.black,
                // bodyFontColor: $themeColors.black
            },
            layout: {
                padding: {
                    top: -15,
                    bottom: -25,
                    left: -15
                }
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        stepSize: 1,
                        min: 0,
                        max: 10,
                        fontColor: chartColors.labelColor
                    },
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    }
                }]
            },
            legend: {
                position: "top",
                align: "start",
                labels: {
                    usePointStyle: true,
                    padding: 25,
                    boxWidth: 9
                }
            }
        },
        data: {
            labels: ["2021-06-21","2021-06-28","2021-07-05","2021-07-12","2021-07-19","2021-07-26","2021-08-02","2021-08-09","2021-08-16","2021-08-23","2021-08-30","2021-09-06","2021-09-13","2021-09-20","2021-09-27","2021-10-04","2021-10-11","2021-10-18","2021-10-25","2021-11-01","2021-11-08","2021-11-15","2021-11-22","2021-11-29","2021-12-06","2021-12-13","2021-12-20","2021-12-27","2022-01-03","2022-01-10","2022-01-17","2022-01-24","2022-01-31","2022-02-07","2022-02-14","2022-02-21","2022-02-28","2022-03-07","2022-03-14","2022-03-21","2022-03-28","2022-04-04","2022-04-11","2022-04-18","2022-04-25","2022-05-02","2022-05-09","2022-05-16","2022-05-23","2022-05-30","2022-06-06","2022-06-13","2022-06-20"
            ],
            datasets: [
                {
                    data:
                        [5.94,5.98,6.05,6.17,6.27,6.13,5.95,5.85,5.84,5.88,5.87,5.71,5.79,5.77,5.74,5.56,5.35,5.17,5.11,5.03,5.01,4.96,4.82,4.75,4.88,5.07,5.13,5.10,4.93,4.73,4.65,4.60,4.53,4.43,4.43,4.73,4.81,4.88,4.96,4.86,4.76,4.65,4.62,4.61,4.57,4.55,4.59,4.61,4.58,4.65,4.81,4.99,5.05,],
                    label: "2-3",
                    borderColor: chartColors.pcAltum1,
                    lineTension: 0.5,
                    pointStyle: "circle",
                    backgroundColor: chartColors.pcAltum1,
                    fill: false,
                    pointRadius: 3,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: "transparent",
                    pointHoverBorderColor: $themeColors.white,
                    pointHoverBackgroundColor: chartColors.pcAltum1,
                    pointShadowOffsetX: 1,
                    pointShadowOffsetY: 1,
                    pointShadowBlur: 5,
                    pointShadowColor: chartColors.tooltipShadow
                },
                {
                    data: [6.17,6.11,6.03,5.94,5.87,5.84,5.82,5.82,5.79,5.76,5.75,5.74,5.70,5.53,5.29,5.07,5.21,5.49,5.58,5.52,5.28,5.03,4.92,4.96,5.10,5.20,5.29,5.41,5.57,5.73,5.73,5.58,5.48,5.49,5.60,5.65,5.58,5.53,5.53,5.55,5.42,5.13,4.86,4.71,4.64,4.59,4.53,4.34,4.13,4.19,4.56,4.85,4.85,
                    ],
                    label: "3-4",
                    borderColor: chartColors.pcAltum2,
                    lineTension: 0.5,
                    pointStyle: "circle",
                    backgroundColor: chartColors.pcAltum2,
                    fill: false,
                    pointRadius: 3,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: "transparent",
                    pointHoverBorderColor: $themeColors.white,
                    pointHoverBackgroundColor: chartColors.pcAltum2,
                    pointShadowOffsetX: 1,
                    pointShadowOffsetY: 1,
                    pointShadowBlur: 5,
                    pointShadowColor: chartColors.tooltipShadow
                },
                {
                    data: [6.13,6.17,6.25,6.37,6.47,6.35,6.18,6.08,6.07,6.10,6.08,5.97,6.00,5.98,5.96,5.82,5.63,5.48,5.41,5.34,5.30,5.26,5.14,5.06,5.17,5.34,5.40,5.37,5.20,4.96,4.84,4.78,4.70,4.61,4.62,4.91,4.98,5.06,5.16,5.06,4.97,4.87,4.84,4.83,4.77,4.76,4.81,4.83,4.80,4.87,5.03,5.23,5.28,
                    ],
                    label: "4-5",
                    borderColor: chartColors.pcAltum3,
                    lineTension: 0.5,
                    pointStyle: "circle",
                    backgroundColor: chartColors.pcAltum3,
                    fill: false,
                    pointRadius: 3,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: "transparent",
                    pointHoverBorderColor: $themeColors.white,
                    pointHoverBackgroundColor: chartColors.pcAltum3,
                    pointShadowOffsetX: 1,
                    pointShadowOffsetY: 1,
                    pointShadowBlur: 5,
                    pointShadowColor: chartColors.tooltipShadow
                },
                // {
                //     data: [3.85, 3.85, 3.85, 3.85, 3.85, 3.85, 3.85],
                //     label: "4-5",
                //     borderColor: chartColors.pcAltum4,
                //     lineTension: 0.5,
                //     pointStyle: "circle",
                //     backgroundColor: chartColors.pcAltum4,
                //     fill: false,
                //     pointRadius: 3,
                //     pointHoverRadius: 5,
                //     pointHoverBorderWidth: 5,
                //     pointBorderColor: "transparent",
                //     pointHoverBorderColor: $themeColors.white,
                //     pointHoverBackgroundColor: chartColors.pcAltum4,
                //     pointShadowOffsetX: 1,
                //     pointShadowOffsetY: 1,
                //     pointShadowBlur: 5,
                //     pointShadowColor: chartColors.tooltipShadow
                // }
            ]
        }
    },
    lineChartML2: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            backgroundColor: false,
            hover: {
                mode: "label"
            },
            tooltips: {
                // Updated default tooltip UI
                shadowColor: chartColors.greyLightColor,
                backgroundColor: "#ffffff",
                titleFontColor: "#000000",
                bodyFontColor: "#000000",
                borderColor: 'rgba(0, 0, 0, 0.3)',
                borderWidth: 1,
                padding: 8
            },
            layout: {
                padding: {
                    top: -15,
                    bottom: -25,
                    left: -15
                }
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        stepSize: 1,
                        min: 0,
                        max: 10,
                        fontColor: chartColors.labelColor
                    },
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    }
                }]
            },
            legend: {
                position: "top",
                align: "start",
                labels: {
                    usePointStyle: true,
                    padding: 25,
                    boxWidth: 9
                }
            }
        },
        data: {
            labels: ["2021-07-05","2021-07-12","2021-07-19","2021-07-26","2021-08-02","2021-08-09","2021-08-16","2021-08-23","2021-08-30","2021-09-06","2021-09-13","2021-09-20","2021-09-27","2021-10-04","2021-10-11","2021-10-18","2021-10-25","2021-11-01","2021-11-08","2021-11-15","2021-11-22","2021-11-29","2021-12-06","2021-12-13","2021-12-20","2021-12-27","2022-01-03","2022-01-10","2022-01-17","2022-01-24","2022-01-31","2022-02-07","2022-02-14","2022-02-21","2022-02-28","2022-03-07","2022-03-14","2022-03-21","2022-03-28","2022-04-04","2022-04-11","2022-04-18","2022-04-25","2022-05-02","2022-05-09","2022-05-16","2022-05-23","2022-05-30","2022-06-06","2022-06-13","2022-06-20","2022-06-27","2022-07-04"
            ],
            datasets: [{
                data: [8.38,8.36,8.09,7.91,7.76,7.59,7.48,7.36,7.27,7.27,6.80,6.96,6.97,6.99,6.97,6.80,6.78,6.72,6.61,6.48,6.34,6.36,6.34,6.59,6.61,6.59,6.48,6.30,5.98,6.19,6.22,6.13,6.09,6.00,6.18,6.21,6.16,6.30,6.28,6.17,6.05,6.03,6.08,6.13,6.25,6.45,6.35,6.51,6.67,6.65,6.35,6.06,6.03
                ],
                label: "10-12",
                borderColor: chartColors.pcAltum1,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.pcAltum1,
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.pcAltum1,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [8.41,
                    8.31,
                    7.49,
                    7.49,
                    7.49,
                    7.51,
                    7.04,
                    6.91,
                    6.58,
                    6.59,
                    7.49,
                    7.32,
                    7.38,
                    6.48,
                    6.45,
                    6.56,
                    6.58,
                    6.54,
                    6.50,
                    6.38,
                    6.46,
                    6.45,
                    5.99,
                    5.95,
                    6.31,
                    5.81,
                    6.21,
                    6.32,
                    6.28,
                    6.23,
                    5.90,
                    5.53,
                    5.67,
                    5.57,
                    5.78,
                    5.38,
                    5.35,
                    5.63,
                    5.58,
                    5.52,
                    5.73,
                    5.59,
                    5.39,
                    5.12,
                    5.18,
                    5.30,
                    5.18,
                    5.39,
                    5.55,
                    5.92,
                    6.11,
                    6.47,
                    6.46,],
                label: "8-10",
                borderColor: chartColors.pcAltum2,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.pcAltum2,
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.pcAltum2,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [
                    6.78,
                    6.79,
                    6.78,
                    6.76,
                    6.60,
                    6.59,
                    6.51,
                    6.43,
                    6.22,
                    6.18,
                    6.20,
                    6.18,
                    6.44,
                    6.49,
                    6.49,
                    6.34,
                    6.13,
                    5.84,
                    6.04,
                    6.03,
                    5.96,
                    6.00,
                    5.88,
                    6.08,
                    6.08,
                    6.02,
                    6.15,
                    6.11,
                    6.05,
                    5.76,
                    5.78,
                    5.87,
                    5.98,
                    6.09,
                    6.31,
                    6.30,
                    6.48,
                    6.67,
                    6.66,
                    6.33,
                    6.01,
                    6.00,],
                label: "12-14",
                borderColor: chartColors.pcAltum3,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.pcAltum3,
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.pcAltum3,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [8.39,
                    8.43,
                    8.14,
                    7.90,
                    7.70,
                    7.54,
                    7.45,
                    7.27,
                    7.26,
                    7.20,
                    6.48,
                    6.81,
                    6.76,
                    6.78,
                    6.78,
                    6.61,
                    6.60,
                    6.51,
                    6.45,
                    6.28,
                    6.21,
                    6.21,
                    6.16,
                    6.41,
                    6.50,
                    6.49,
                    6.32,
                    6.11,
                    5.81,
                    6.04,
                    6.00,
                    5.95,
                    6.12,
                    5.91,
                    6.08,
                    6.09,
                    6.03,
                    6.13,
                    6.09,
                    5.95,
                    5.77,
                    5.70,
                    5.88,
                    5.97,
                    6.05,
                    6.30,
                    6.29,
                    6.50,
                    6.71,
                    6.66,
                    6.33,
                    6.02,
                    6.00,],
                label: "14-16",
                borderColor: chartColors.pcAltum4,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.pcAltum4,
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.pcAltum4,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [8.37,
                    8.27,
                    8.06,
                    7.78,
                    7.73,
                    7.56,
                    7.46,
                    7.24,
                    7.23,
                    7.13,
                    6.69,
                    6.83,
                    6.79,
                    6.72,
                    6.84,
                    6.67,
                    6.65,
                    6.58,
                    6.53,
                    6.41,
                    6.27,
                    6.27,
                    6.22,
                    6.43,
                    6.52,
                    6.53,
                    6.39,
                    6.17,
                    5.91,
                    6.15,
                    6.09,
                    6.11,
                    6.32,
                    6.06,
                    6.27,
                    6.20,
                    6.14,
                    6.30,
                    6.25,
                    6.13,
                    5.93,
                    5.88,
                    6.06,
                    6.20,
                    6.25,
                    6.45,
                    6.35,
                    6.51,
                    6.77,
                    6.78,
                    6.36,
                    6.07,
                    6.05],
                label: "16-18",
                borderColor: chartColors.pcAltum5,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.pcAltum5,
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.pcAltum5,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
                // {
                //     data: [3.85, 3.85, 3.85, 3.85, 3.85, 3.85, 3.85],
                //     label: "16-18",
                //     borderColor: chartColors.pcAltum6,
                //     lineTension: 0.5,
                //     pointStyle: "circle",
                //     backgroundColor: chartColors.pcAltum6,
                //     fill: false,
                //     pointRadius: 1,
                //     pointHoverRadius: 5,
                //     pointHoverBorderWidth: 5,
                //     pointBorderColor: "transparent",
                //     pointHoverBorderColor: $themeColors.white,
                //     pointHoverBackgroundColor: chartColors.pcAltum6,
                //     pointShadowOffsetX: 1,
                //     pointShadowOffsetY: 1,
                //     pointShadowBlur: 5,
                //     pointShadowColor: chartColors.tooltipShadow
                // }
            ]
        }
    },
    lineChart: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            backgroundColor: false,
            hover: {
                mode: "label"
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.white,
                titleFontColor: $themeColors.black,
                bodyFontColor: $themeColors.black
            },
            layout: {
                padding: {
                    top: -15,
                    bottom: -25,
                    left: -15
                }
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        stepSize: 1,
                        min: 0,
                        max: 8,
                        fontColor: chartColors.labelColor
                    },
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    }
                }]
            },
            legend: {
                position: "top",
                align: "start",
                labels: {
                    usePointStyle: true,
                    padding: 25,
                    boxWidth: 9
                }
            }
        },
        data: {
            labels: ["01", "02", "03", "04", "05", "06", "07"],
            datasets: [{
                data: [0, 0, 0, 0, 0, 0, 0],
                label: "1-2H",
                borderColor: chartColors.lineChartDanger,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.lineChartDanger,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.lineChartDanger,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [3.75, 3.75, 3.75, 3.75, 3.75, 3.75, 3.75],
                label: "2-3 Low",
                borderColor: chartColors.lineChartPrimary,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.lineChartPrimary,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.lineChartPrimary,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [3.85, 3.85, 3.85, 3.85, 3.85, 3.85, 3.85],
                label: "2-3 High",
                borderColor: chartColors.warningColorShade,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.warningColorShade,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.warningColorShade,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [3.85, 3.85, 3.85, 3.85, 3.85, 3.85, 3.85],
                label: "3-4 Low",
                borderColor: chartColors.successColorShade,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.successColorShade,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.successColorShade,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [3.95, 3.95, 3.95, 3.95, 3.95, 3.95, 3.95],
                label: "3-4 High",
                borderColor: chartColors.blueColorH,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.blueColorH,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.blueColorH,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            },
            {
                data: [3.95, 3.95, 3.95, 3.95, 3.95, 3.95, 3.95],
                label: "4-5 Low",
                borderColor: chartColors.blueLightColor,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: chartColors.blueLightColor,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: chartColors.blueLightColor,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: chartColors.tooltipShadow
            }
            ]
        }
    },
    // noruega
    lineChart02: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            backgroundColor: false,
            hover: {
                mode: "label"
            },
            tooltips: {
                shadowColor: chartColors.greyLightColor,
                backgroundColor: "#ffffff",
                titleFontColor: "#000000",
                bodyFontColor: "#000000",
                borderColor: 'rgba(0, 0, 0, 0.3)',
                borderWidth: 1,
                padding: 8
            },
            layout: {
                padding: {
                    top: -15,
                    bottom: -25,
                    left: -15
                }
            },
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        stepSize: 10,
                        min: 0,
                        max: 100,
                        fontColor: chartColors.labelColor
                    },
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    }
                }]
            },
            legend: {
                position: "top",
                align: "start",
                labels: {
                    usePointStyle: true,
                    padding: 25,
                    boxWidth: 9
                }
            }
        },
        data: {
            labels: [
                "2021-09-20",
                "2021-09-27",
                "2021-10-04",
                "2021-10-11",
                "2021-10-18",
                "2021-10-25",
                "2021-11-01",
                "2021-11-08",
                "2021-11-15",
                "2021-11-22",
                "2021-11-29",
                "2021-12-06",
                "2021-12-13",
                "2021-12-20",
                "2021-12-27",
                "2022-01-03",
                "2022-01-10",
                "2022-01-17",
                "2022-01-24",
                "2022-01-31",
                "2022-02-07",
                "2022-02-14",
                "2022-02-21",
                "2022-02-28",
                "2022-03-07",
                "2022-03-14",
                "2022-03-21",
                "2022-03-28",
                "2022-04-04",
                "2022-04-11",
                "2022-04-18",
                "2022-04-25",
                "2022-05-02",
                "2022-05-09",
                "2022-05-16",
                "2022-05-23",
                "2022-05-30",
                "2022-06-06",
                "2022-06-13",
                "2022-06-20",
                "2022-06-27",
                "2022-07-04",
                "2022-07-11",
                "2022-07-18",
                "2022-07-25",
                "2022-08-01",
                "2022-08-08",
                "2022-08-15",
                "2022-08-22",
                "2022-08-29",
                "2022-09-05",
                "2022-09-12",
                "2022-09-19"

            ],
            datasets: [
                {
                    data: [
                        48.29,
                        46.78,
                        47.99,
                        52.06,
                        52.05,
                        51.52,
                        51.02,
                        57.02,
                        61.34,
                        62.36,
                        62.20,
                        69.42,
                        74.96,
                        77.95,
                        78.76,
                        79.77,
                        79.03,
                        77.74,
                        70.24,
                        65.31,
                        62.63,
                        68.12,
                        74.80,
                        67.82,
                        67.96,
                        59.68,
                        56.14,
                        53.79,
                        54.19,
                        53.37,
                        53.31,
                        50.53,
                        49.74,
                        56.99,
                        67.44,
                        66.43,
                        70.66,
                        73.01,
                        62.05,
                        58.12,
                        59.69,
                        54.90,
                        48.56,
                        46.11,
                        45.07,
                        45.09,
                        48.69,
                        49.20,
                        46.50,
                        46.46,
                        47.48,
                        45.78,
                        49.11
                    ],
                    label: "Nor",
                    borderColor: chartColors.pcAltum1,
                    lineTension: 0.5,
                    pointStyle: "circle",
                    backgroundColor: chartColors.pcAltum1,
                    fill: false,
                    pointRadius: 3,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: "transparent",
                    pointHoverBorderColor: $themeColors.white,
                    pointHoverBackgroundColor: chartColors.pcAltum1,
                    pointShadowOffsetX: 1,
                    pointShadowOffsetY: 1,
                    pointShadowBlur: 5,
                    pointShadowColor: chartColors.tooltipShadow
                },
                // {
                //     data: [462, 488, 335, 360, 490, 383],
                //     label: "Frozen",
                //     borderColor: chartColors.pcAltum2,
                //     lineTension: 0.5,
                //     pointStyle: "circle",
                //     backgroundColor: chartColors.pcAltum2,
                //     fill: false,
                //     pointRadius: 1,
                //     pointHoverRadius: 5,
                //     pointHoverBorderWidth: 5,
                //     pointBorderColor: "transparent",
                //     pointHoverBorderColor: $themeColors.white,
                //     pointHoverBackgroundColor: chartColors.pcAltum2,
                //     pointShadowOffsetX: 1,
                //     pointShadowOffsetY: 1,
                //     pointShadowBlur: 5,
                //     pointShadowColor: chartColors.tooltipShadow
                // },
                // {
                //     data: [65.68, 64.75, 58.82, 57.19, 50.09, 61.52],
                //     label: "Nasdaq",
                //     borderColor: chartColors.pcAltum3,
                //     lineTension: 0.5,
                //     pointStyle: "circle",
                //     backgroundColor: chartColors.pcAltum3,
                //     fill: false,
                //     pointRadius: 1,
                //     pointHoverRadius: 5,
                //     pointHoverBorderWidth: 5,
                //     pointBorderColor: "transparent",
                //     pointHoverBorderColor: $themeColors.white,
                //     pointHoverBackgroundColor: chartColors.pcAltum3,
                //     pointShadowOffsetX: 1,
                //     pointShadowOffsetY: 1,
                //     pointShadowBlur: 5,
                //     pointShadowColor: chartColors.tooltipShadow
                // },
                // {
                //     data: [6.15, 6.16, 5.76, 5.69, 5.88, 6.1],
                //     label: "US",
                //     borderColor: chartColors.pcAltum4,
                //     lineTension: 0.5,
                //     pointStyle: "circle",
                //     backgroundColor: chartColors.pcAltum4,
                //     fill: false,
                //     pointRadius: 1,
                //     pointHoverRadius: 5,
                //     pointHoverBorderWidth: 5,
                //     pointBorderColor: "transparent",
                //     pointHoverBorderColor: $themeColors.white,
                //     pointHoverBackgroundColor: chartColors.pcAltum4,
                //     pointShadowOffsetX: 1,
                //     pointShadowOffsetY: 1,
                //     pointShadowBlur: 5,
                //     pointShadowColor: chartColors.tooltipShadow
                // }
            ]
        }
    },
    radarChart: {
        data: {
            labels: ["STA", "STR", "AGI", "VIT", "CHA", "INT"],
            datasets: [{
                label: "Donté Panlin",
                data: [25, 59, 90, 81, 60, 82],
                fill: true,
                backgroundColor: "rgba(255,161,161, 0.9)",
                borderColor: "transparent",
                pointBackgroundColor: "transparent",
                pointBorderColor: "transparent"
            },
            {
                label: "Mireska Sunbreeze",
                data: [40, 100, 40, 90, 40, 90],
                fill: true,
                backgroundColor: "rgba(155,136,250, 0.9)",
                borderColor: "transparent",
                pointBackgroundColor: "transparent",
                pointBorderColor: "transparent"
            }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
                position: "top",
                labels: {
                    padding: 25,
                    fontColor: chartColors.labelColor
                }
            },
            tooltips: {
                enabled: false,
                custom(tooltip) {
                    const tooltipEl = document.getElementById("tooltip");
                    if (tooltip.body) {
                        tooltipEl.style.display = "block";
                        if (tooltip.body[0].lines && tooltip.body[0].lines[0]) {
                            // eslint-disable-next-line prefer-destructuring
                            tooltipEl.innerHTML = tooltip.body[0].lines[0];
                        }
                    } else {
                        setTimeout(() => {
                            tooltipEl.style.display = "none";
                        }, 500);
                    }
                }
            },
            gridLines: {
                display: false
            },
            scale: {
                ticks: {
                    maxTicksLimit: 1,
                    display: false,
                    fontColor: chartColors.labelColor
                },
                gridLines: {
                    color: chartColors.grid_line_color
                },
                angleLines: { color: chartColors.grid_line_color }
            }
        }
    },
    operationalChartProduction: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
                position: "right",
                labels: {
                    usePointStyle: true,
                    padding: 25,
                    boxWidth: 10,
                    fontColor: chartColors.labelColor
                }
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            },
            scale: {
                scaleShowLine: true,
                scaleLineWidth: 1,
                ticks: {
                    display: false,
                    fontColor: chartColors.labelColor
                },
                reverse: false,
                gridLines: {
                    display: false
                }
            },
            animation: {
                animateRotate: false
            }
        },
        data: {
            labels: [
                "Sandina",
                "Salmòn LM",
                "HL-LP",
                "Langostino",
                "Trucha",
                "Mix Especies",
                "Mix Varios",
                "Pesca Salvaje",
                "PH",
                "Jibia",
                "Amino Salmòn"
            ],
            datasets: [{
                label: "Population (millions)",
                backgroundColor: [
                    chartColors.blueColorH,
                    chartColors.blueColorD,
                    chartColors.blueColorW,
                    chartColors.blueColorS
                ],
                data: [10, 45.5, 11, 34, 7, 15, 6, 30, 22, 23],
                borderWidth: 0
            }]
        }
    },
    operationalChart: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
                position: "right",
                labels: {
                    usePointStyle: true,
                    padding: 25,
                    boxWidth: 10,
                    fontColor: chartColors.labelColor
                }
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            },
            scale: {
                scaleShowLine: true,
                scaleLineWidth: 1,
                ticks: {
                    display: false,
                    fontColor: chartColors.labelColor
                },
                reverse: false,
                gridLines: {
                    display: false
                }
            },
            animation: {
                animateRotate: false
            }
        },
        data: {
            labels: ["Calbuco", "Coronel"],
            datasets: [{
                label: "Population (millions)",
                backgroundColor: [
                    chartColors.blueColorH,
                    chartColors.blueColorD
                ],
                data: [55, 45.5],
                borderWidth: 0
            }]
        }
    },
    polarChart: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
                position: "right",
                labels: {
                    usePointStyle: true,
                    padding: 25,
                    boxWidth: 10,
                    fontColor: chartColors.labelColor
                }
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            },
            scale: {
                scaleShowLine: true,
                scaleLineWidth: 1,
                ticks: {
                    display: false,
                    fontColor: chartColors.labelColor
                },
                reverse: false,
                gridLines: {
                    display: false
                }
            },
            animation: {
                animateRotate: false
            }
        },
        data: {
            labels: ["1 trim", "2 trim", "3 trim", "4 trim"],
            datasets: [{
                label: "Population (millions)",
                backgroundColor: [
                    chartColors.blueColorH,
                    chartColors.blueColorD,
                    $themeColors.blueColorG,
                    chartColors.blueColorW,
                    chartColors.blueColorG
                ],
                data: [19, 17.5, 15, 13.5],
                borderWidth: 0
            }]
        }
    },
    bubbleChart: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    display: true,
                    gridLines: {
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        stepSize: 10,
                        min: 0,
                        max: 140,
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    display: true,
                    gridLines: {
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        stepSize: 100,
                        min: 0,
                        max: 400,
                        fontColor: chartColors.labelColor
                    }
                }]
            },
            legend: {
                display: false
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            }
        },
        data: {
            animation: {
                duration: 10000
            },
            datasets: [{
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 20,
                    y: 74,
                    r: 10
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 30,
                    y: 72,
                    r: 5
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 10,
                    y: 110,
                    r: 5
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 40,
                    y: 110,
                    r: 7
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 20,
                    y: 135,
                    r: 6
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 10,
                    y: 160,
                    r: 12
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 30,
                    y: 165,
                    r: 7
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 40,
                    y: 200,
                    r: 20
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 90,
                    y: 185,
                    r: 7
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 50,
                    y: 240,
                    r: 7
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 60,
                    y: 275,
                    r: 10
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 70,
                    y: 305,
                    r: 5
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 80,
                    y: 325,
                    r: 4
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 50,
                    y: 285,
                    r: 5
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 60,
                    y: 235,
                    r: 5
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 70,
                    y: 275,
                    r: 7
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 80,
                    y: 290,
                    r: 4
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 90,
                    y: 250,
                    r: 10
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 100,
                    y: 220,
                    r: 7
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 120,
                    y: 230,
                    r: 4
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 110,
                    y: 320,
                    r: 15
                }]
            },
            {
                backgroundColor: chartColors.warningColorShade,
                borderColor: chartColors.warningColorShade,
                data: [{
                    x: 130,
                    y: 330,
                    r: 7
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 100,
                    y: 310,
                    r: 5
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 110,
                    y: 240,
                    r: 5
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 120,
                    y: 270,
                    r: 7
                }]
            },
            {
                backgroundColor: chartColors.primaryColorShade,
                borderColor: chartColors.primaryColorShade,
                data: [{
                    x: 130,
                    y: 300,
                    r: 6
                }]
            }
            ]
        }
    },
    doughnutChart: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            cutoutPercentage: 60,
            legend: { display: false },
            tooltips: {
                callbacks: {
                    label(tooltipItem, data) {
                        const label =
                            data.datasets[0].labels[tooltipItem.index] || "";
                        const value = data.datasets[0].data[tooltipItem.index];
                        const output = ` ${label} : ${value} %`;
                        return output;
                    }
                },
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            }
        },
        data: {
            datasets: [{
                labels: ["Tablet", "Mobile", "Desktop"],
                data: [10, 10, 80],
                backgroundColor: [
                    chartColors.successColorShade,
                    chartColors.warningLightColor,
                    $themeColors.primary
                ],
                borderWidth: 0,
                pointStyle: "rectRounded"
            }]
        }
    },
    scatterChart: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 800,
            title: {
                display: false,
                text: "Chart.js Scatter Chart"
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        stepSize: 10,
                        min: 0,
                        max: 140,
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    gridLines: {
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        stepSize: 100,
                        min: 0,
                        max: 400,
                        fontColor: chartColors.labelColor
                    }
                }]
            },
            legend: {
                position: "top",
                align: "start",
                display: true,
                labels: {
                    usePointStyle: true,
                    padding: 15,
                    boxWidth: 7
                }
            }
        },
        data: {
            datasets: [{
                label: "iPhone",
                data: [{
                    x: 72,
                    y: 225
                },
                {
                    x: 81,
                    y: 270
                },
                {
                    x: 90,
                    y: 230
                },
                {
                    x: 103,
                    y: 305
                },
                {
                    x: 103,
                    y: 245
                },
                {
                    x: 108,
                    y: 275
                },
                {
                    x: 110,
                    y: 290
                },
                {
                    x: 111,
                    y: 315
                },
                {
                    x: 109,
                    y: 350
                },
                {
                    x: 116,
                    y: 340
                },
                {
                    x: 113,
                    y: 260
                },
                {
                    x: 117,
                    y: 275
                },
                {
                    x: 117,
                    y: 295
                },
                {
                    x: 126,
                    y: 280
                },
                {
                    x: 127,
                    y: 340
                },
                {
                    x: 133,
                    y: 330
                }
                ],
                backgroundColor: $themeColors.primary,
                borderColor: "transparent",
                pointBorderWidth: 2,
                pointHoverBorderWidth: 2,
                pointRadius: 5
            },
            {
                label: "Samsung Note",
                data: [{
                    x: 13,
                    y: 95
                },
                {
                    x: 22,
                    y: 105
                },
                {
                    x: 17,
                    y: 115
                },
                {
                    x: 19,
                    y: 130
                },
                {
                    x: 21,
                    y: 125
                },
                {
                    x: 35,
                    y: 125
                },
                {
                    x: 13,
                    y: 155
                },
                {
                    x: 21,
                    y: 165
                },
                {
                    x: 25,
                    y: 155
                },
                {
                    x: 18,
                    y: 190
                },
                {
                    x: 26,
                    y: 180
                },
                {
                    x: 43,
                    y: 180
                },
                {
                    x: 53,
                    y: 202
                },
                {
                    x: 61,
                    y: 165
                },
                {
                    x: 67,
                    y: 225
                }
                ],
                backgroundColor: chartColors.yellowColor,
                borderColor: "transparent",
                pointRadius: 5
            },
            {
                label: "OnePlus",
                data: [{
                    x: 70,
                    y: 195
                },
                {
                    x: 72,
                    y: 270
                },
                {
                    x: 98,
                    y: 255
                },
                {
                    x: 100,
                    y: 215
                },
                {
                    x: 87,
                    y: 240
                },
                {
                    x: 94,
                    y: 280
                },
                {
                    x: 99,
                    y: 300
                },
                {
                    x: 102,
                    y: 290
                },
                {
                    x: 110,
                    y: 275
                },
                {
                    x: 111,
                    y: 250
                },
                {
                    x: 94,
                    y: 280
                },
                {
                    x: 92,
                    y: 340
                },
                {
                    x: 100,
                    y: 335
                },
                {
                    x: 108,
                    y: 330
                }
                ],
                backgroundColor: chartColors.successColorShade,
                borderColor: "transparent",
                pointBorderWidth: 2,
                pointHoverBorderWidth: 2,
                pointRadius: 5
            }
            ]
        }
    },
    lineAreaChart: {
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                position: "top",
                align: "start",
                display: true,
                labels: {
                    usePointStyle: true,
                    padding: 15,
                    boxWidth: 8
                }
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            },
            scales: {
                xAxes: [{
                    display: true,
                    gridLines: {
                        color: "transparent",
                        zeroLineColor: chartColors.grid_line_color
                    },
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    display: true,
                    gridLines: {
                        color: "transparent",
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        stepSize: 100,
                        min: 0,
                        max: 400,
                        fontColor: chartColors.labelColor
                    },
                    scaleLabel: {
                        display: true
                    }
                }]
            }
        },
        data: {
            labels: [
                "7/12",
                "8/12",
                "9/12",
                "10/12",
                "11/12",
                "12/12",
                "13/12",
                "14/12",
                "15/12",
                "16/12",
                "17/12",
                "18/12",
                "19/12",
                "20/12",
                ""
            ],
            datasets: [{
                label: "Africa",
                data: [
                    40,
                    55,
                    45,
                    75,
                    65,
                    55,
                    70,
                    60,
                    100,
                    98,
                    90,
                    120,
                    125,
                    140,
                    155
                ],
                lineTension: 0,
                backgroundColor: chartColors.blueColor,
                pointStyle: "circle",
                borderColor: "transparent",
                pointRadius: 0.5,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBackgroundColor: chartColors.blueColor,
                pointHoverBorderColor: $themeColors.light
            },
            {
                label: "Asia",
                data: [
                    70,
                    85,
                    75,
                    150,
                    100,
                    140,
                    110,
                    105,
                    160,
                    150,
                    125,
                    190,
                    200,
                    240,
                    275
                ],
                lineTension: 0,
                backgroundColor: chartColors.blueLightColor,
                pointStyle: "circle",
                borderColor: "transparent",
                pointRadius: 0.5,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBackgroundColor: chartColors.blueLightColor,
                pointHoverBorderColor: $themeColors.light
            },
            {
                label: "Europe",
                data: [
                    240,
                    195,
                    160,
                    215,
                    185,
                    215,
                    185,
                    200,
                    250,
                    210,
                    195,
                    250,
                    235,
                    300,
                    315
                ],
                lineTension: 0,
                backgroundColor: chartColors.greyLightColor,
                pointStyle: "circle",
                borderColor: "transparent",
                pointRadius: 0.5,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBackgroundColor: chartColors.greyLightColor,
                pointHoverBorderColor: $themeColors.light
            }
            ]
        }
    },

    // detalle planificacion - comercial
    proyeccionBarChart: {
        data: {
            labels: [
                "Mes 1",
                "Mes 2",
                "Mes 3",
                "Mes 4",
                "Mes 5",
                "Mes 6",
                "Mes 7",
                "Mes 8",
                "Mes 9",
                "Mes 10",
                "Mes 11",
                "Mes 12"
            ],
            datasets: [{
                data: [12837642, 14763892, 9889273, 6827222, 7332545, 5837365, 5242424, 12188268, 11992722, 6726552, 5837365, 5242424],
                backgroundColor: chartColors.pcAltum1,
                borderColor: "transparent"
            }]
        },
        options: {
            elements: {
                rectangle: {
                    borderWidth: 0,
                    borderSkipped: "bottom"
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
                display: false
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark
            },
            scales: {
                xAxes: [{
                    display: true,
                    gridLines: {
                        display: true,
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    scaleLabel: {
                        display: false
                    },
                    ticks: {
                        fontColor: chartColors.labelColor
                    }
                }],
                yAxes: [{
                    display: true,
                    gridLines: {
                        color: chartColors.grid_line_color,
                        zeroLineColor: chartColors.grid_line_color
                    },
                    ticks: {
                        stepSize: 5000000,
                        min: 0,
                        max: 15000000,
                        fontColor: chartColors.labelColor
                    }
                }]
            }
        }
    },
};