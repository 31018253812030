<template>
  <b-carousel id="carousel-caption" controls>
    <b-carousel-slide
      caption-html="<span class='text-white'>Lesión Lobos</span>"
      class="card-img-overlay"
      text="Centro Chonons, Jaula 207, Lesión Media, hasta 2 heridas < 1.5cm"
    >
      <template #img>
        <img
          :src="
            require('@/assets/images/identificacion-niveles-defectos/2/2.png')
          "
          alt="lesion"
        />
      </template>
    </b-carousel-slide>
    <b-carousel-slide
      caption-html="<span class='text-white'>Lesión Branquias</span>"
      class="card-img-overlay"
      text="Centro Izaza, Jaula 203R, Lesión Leve 1%"
    >
      <template #img>
        <img
          :src="
            require('@/assets/images/identificacion-niveles-defectos/0/1.png')
          "
          alt="lesion"
        />
      </template>
    </b-carousel-slide>
    <b-carousel-slide
      caption-html="<span class='text-white'>Madurez</span>"
      class="card-img-overlay"
      text="Centro Canal Piure, Jaula 201, Madurez Media en piel"
    >
      <template #img>
        <img
          :src="
            require('@/assets/images/identificacion-niveles-defectos/3/2.png')
          "
          alt="lesion"
        />
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>
<script>
import { BCarousel, BCarouselSlide } from "bootstrap-vue";

export default {
  data() {
    return {};
  },
  components: {
    BCarousel,
    BCarouselSlide,
  },
};
</script>

<style>
.text-white {
  color: black !important;
}

.carousel-caption {
  text-shadow: 1px 1px 2px rgb(228, 221, 221) 233, 226, 226;
  background: linear-gradient(0deg, white, #ffffff00);
  bottom: 0%;
}

.carousel-caption > p {
  font-size: 16px;
  color: black !important;
}
.carousel-caption > h3 > span {
  font-size: 20px;
  color: black !important;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: #F26E28;
  width: 55px;
  height: 55px;
}
.carousel-control-prev {
  left: -3%;
}
.carousel-control-next {
  right: -3%;
}

.carousel-item {
  height: 400px;
}
.carousel-item > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  height: 40rem;
  margin: 0 auto;
}
</style>