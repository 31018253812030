<template>
  <section id="dashboard-global-muestreo">
    <cosecha-urgente></cosecha-urgente>
    <screenshot name-pdf="Dashboard Global Muestreo"></screenshot>

    <b-card>
      <b-card-title> </b-card-title>
      <b-card-body>
        <b-row>
          <b-col sm="12" md="6" xl="6" class="mb-3">
            <app-collapse accordion>
              <app-collapse-item title="Seleccionar Centro" class="w-100">
                <b-form-group>
                  <b-form-checkbox
                    v-for="option in centros_options"
                    :key="option.value"
                    v-model="centro_selected"
                    :value="option.value"
                    name="flavour-3a"
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </b-form-group>
              </app-collapse-item>
            </app-collapse>
          </b-col>
          <b-col sm="12" md="4" xl="4" class="d-flex justify-content-start">
            <b-button class="btn-standard"> Seleccionar </b-button>
          </b-col>
          <b-col sm="12" md="2" xl="2"> </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card>
      <b-card-title>Detalle Muestreos</b-card-title>
      <b-row>
        <b-col sm="12" md="12" xl="12">
          <good-table-advance
            :search="false"
            :rows="rows"
            :columns="columns"
            :pageLengthtable="10"
            :title="``"
          >
          </good-table-advance>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-card-title> </b-card-title>
      <b-card-body>
        <b-row>
          <b-col sm="12" md="12" xl="12">
            <SliderDashboardMuestreo />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card>
      <b-card-title> Estadísticas de Últimas Jaulas Muestreadas </b-card-title>
      <b-card-body>
        <UltimasJaulasMuestreadas />
      </b-card-body>
    </b-card>

    <b-row>
      <b-col sm="12" md="12" xl="6">
        <b-card style="height: 730px">
          <b-card-title> Distribución por Muestreo </b-card-title>
          <b-card-body>
            <bar-chart
              tooltipsText=""
              :title="``"
              :datachart="chartjsData.ChartDashBoardMuestreo"
            ></bar-chart>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="12" md="12" xl="6">
        <b-card style="height: 730px">
          <b-card-title> Índice de Condición </b-card-title>
          <b-card-body>
            <b-row>
              <b-col></b-col>
              <b-col sm="10">
                <ChartjsLineChart />
              </b-col>
              <b-col></b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <b-card-title> Distribución de Defectos según Centro </b-card-title>
      <b-card-body>
        <b-row>
          <b-col sm="12" md="3" xl="3" class="align-middle mb-3">
            <label>Centros:</label>
            <app-collapse accordion>
              <app-collapse-item title="Seleccionar Centro" class="w-100">
                <b-form-group>
                  <b-form-checkbox
                    v-for="option in centros_options"
                    :key="option.value"
                    v-model="centro_selected"
                    :value="option.value"
                    name="flavour-3a"
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </b-form-group>
              </app-collapse-item>
            </app-collapse>
          </b-col>
          <b-col sm="12" md="6" xl="6" class="align-middle">
            <b-row class="match-height">
              <b-col xl="6" md="6" sm="6">
                <label for="datepicker-placeholder">Fecha desde:</label>
                <b-form-datepicker
                  id="fecha-desde"
                  placeholder="Fecha Desde"
                  local="en"
                  initial-date="2021-11-16"
                />
              </b-col>
              <b-col sm="6" md="6" xl="6">
                <label for="datepicker-placeholder">Fecha hasta:</label>
                <b-form-datepicker
                  id="fecha-hasta"
                  placeholder="Fecha Hasta"
                  local="en"
                  initial-date="2021-11-16"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="3" xl="3" class="d-flex justify-content-center">
            <b-button class="btn-standard align-middle mb-2" style="margin-top:20px;"> Aplicar </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" md="12" xl="12">
            <ApexDataBarChart />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardBody,
  BCardTitle,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormDatepicker,
} from "bootstrap-vue";
import CosechaUrgente from "@/views/clients/blumar/components/CosechaUrgente";
import Screenshot from "@/views/clients/blumar/components/Screenshot";
import GoodTableAdvance from "@/views/clients/blumar/components/Goodtableadvance";
import SliderDashboardMuestreo from "@/views/clients/blumar/muestreo-jaulas/SliderDashboardMuestreo.vue";
import UltimasJaulasMuestreadas from "./UltimasJaulasMuestreadas.vue";
import BarChart from "@/views/clients/blumar/components/Barchart";
import chartjsData from "@/views/clients/blumar/mock/charMockData";
import ChartjsLineChart from "@/views/clients/blumar/muestreo-jaulas/chartjs/ChartjsLineChart1.vue";
import ApexDataBarChart from "./apex-chart/ApexDataBarChart.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  data() {
    return {
      chartjsData,
      rows: [
        {
          centro: "P. Argentino",
          jaula: "PAR 201",
          fmuestreo: "18-04-2021",
          cestimada: "02-05-2021",
          cepa: "FLY",
          sexo: "Macho",
          cv: "20,7",
          k: "1,04",
          media: "3.279",
          minimo: "1950",
          maximo: "4550",
          srs: "0",
          madurez: "28",
          branquias: "12",
          caligus: "100",
          descamacion: "0",
          deformes: "0",
          lobo: "0",
        },
        {
          centro: "P. Argentino",
          jaula: "PAR 203",
          fmuestreo: "18-04-2021",
          cestimada: "02-05-2021",
          cepa: "FLY",
          sexo: "Macho",
          cv: "23,1",
          k: "1,02",
          media: "3.218",
          minimo: "2000",
          maximo: "5500",
          srs: "0",
          madurez: "30",
          branquias: "14",
          caligus: "42",
          descamacion: "0",
          deformes: "0",
          lobo: "0",
        },
        {
          centro: "P. Argentino",
          jaula: "PAR 205",
          fmuestreo: "18-04-2021",
          cestimada: "25-04-2021",
          cepa: "AQG",
          sexo: "Macho",
          cv: "19,7",
          k: "1,01",
          media: "3.422",
          minimo: "1800",
          maximo: "5700",
          srs: "0",
          madurez: "40",
          branquias: "8",
          caligus: "44",
          descamacion: "0",
          deformes: "0",
          lobo: "0",
        },
        {
          centro: "P. Argentino",
          jaula: "PAR 207",
          fmuestreo: "18-04-2021",
          cestimada: "25-04-2021",
          cepa: "AQG",
          sexo: "Macho",
          cv: "14,5",
          k: "1,03",
          media: "3.616",
          minimo: "2700",
          maximo: "4600",
          srs: "0",
          madurez: "28",
          branquias: "20",
          caligus: "60",
          descamacion: "0",
          deformes: "0",
          lobo: "0",
        },
      ],
      centro_selected: [],
      centros_options: [
        { value: "1", text: "Edwards" },
        { value: "2", text: "Mañihueico" },
        { value: "3", text: "Chonos" },
        { value: "4", text: "Canal Piure" },
        { value: "5", text: "P. Lautaro" },
        { value: "6", text: "Islotes" },
        { value: "7", text: "Izaza" },
        { value: "8", text: "W. Filomena" },
      ],

      columns: [
        {
          label: "Centro",
          field: "centro",
        },
        {
          label: "Jaula",
          field: "jaula",
        },
        {
          label: "Fecha Muestreo",
          field: "fmuestreo",
        },
        {
          label: "Cosecha Estimada",
          field: "cestimada",
        },
        {
          label: "Cepa",
          field: "cepa",
        },
        {
          label: "Sexo",
          field: "sexo",
        },
        {
          label: "CV",
          field: "cv",
        },
        {
          label: "K",
          field: "k",
        },
        {
          label: "Media",
          field: "media",
        },
        {
          label: "Mínimo",
          field: "minimo",
        },
        {
          label: "Máximo",
          field: "maximo",
        },
        {
          label: "Máximo",
          field: "maximo",
        },
        {
          label: "SRS",
          field: "srs",
        },
        {
          label: "Madurez",
          field: "madurez",
        },
        {
          label: "Branquias",
          field: "branquias",
        },
        {
          label: "Caligus",
          field: "caligus",
        },
        {
          label: "Descamación",
          field: "descamacion",
        },
        {
          label: "Deformes",
          field: "deformes",
        },
        {
          label: "Lobo",
          field: "lobo",
        },
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardBody,
    BCardTitle,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    CosechaUrgente,
    Screenshot,
    GoodTableAdvance,
    SliderDashboardMuestreo,
    UltimasJaulasMuestreadas,
    BarChart,
    ChartjsLineChart,
    BFormDatepicker,
    ApexDataBarChart,
    AppCollapse,
    AppCollapseItem,
  },
};
</script>

<style>
#bar-chart {
  height: 500px !important;
}

#dashboard-global-muestreo
  > div:nth-child(3)
  > div
  > div
  > div
  > div.col-sm-12.col-md-6.col-xl-6
  > div
  > div
  > div.card-header.collapsed {
  border: 1px solid #d8d6de !important;
}

#dashboard-global-muestreo
  > div:nth-child(9)
  > div
  > div
  > div:nth-child(1)
  > div.col-sm-12.col-md-4.col-xl-4
  > div
  > div
  > div.card-header.collapsed {
  border: 1px solid #d8d6de !important;
}

.collapsed {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

#line-chart {
  max-height: 465px;
}
</style>