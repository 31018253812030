<template>
    <b-card no-body>
        <b-card-header>
            <b-card-title >{{ title }}</b-card-title>
            <!-- datepicker -->
            <div class="d-flex align-items-center">
                <feather-icon
                    icon="CalendarIcon"
                    size="16"
                />
                <flat-pickr
                    v-model="rangePicker"
                    :config="{ mode: 'range'}"
                    class="form-control flat-picker bg-transparent border-0 shadow-none"
                    placeholder="YYYY-MM-DD"
                />
            </div>
            <!-- datepicker -->
        </b-card-header>

        <!-- chart -->
        <b-card-body>
            <chartjs-component-bar-chart
                :height=height
                :data="datachart.data"
                :options="datachart.options"
            />
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard, BCardHeader, BCardBody, BCardTitle, VBTooltip, VBPopover,BAvatar,BCardSubTitle
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from '@/views/vuexy/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue'

export default {
    props: {
        height: Number,
        datachart:Object,
        title: {
            type: String,
            default: '',
        },
        tooltipsText: {
            type: String
        },
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-popover': VBPopover,
    },
    components: {
        BAvatar,
        BCard,
        BCardHeader,
        BCardBody,
        BCardTitle,
        flatPickr,
        ChartjsComponentBarChart,
        BCardSubTitle
    },
    data() {
        return {
            rangePicker: ['2019-05-01', '2019-05-10'],
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.tooltips {
    float: right
}
.popover-header {
    background-color: #053742 !important;
    border: #053742 !important;
}
</style>
