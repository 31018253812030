<template>
  <section>
    <div class="ultimas-jaulas-muestreadas">
      <b-row>
        <b-col
          v-for="(item, index) in items"
          :key="index"
          :class="item.customClass"
          class="mb-1"
          sm="6"
          md="3"
          xl="3"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                class="avatar-custom icono-jaula"
                size="60"
                :variant="item.color"
                :src="item.icon"
              >
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-1">
                {{ item.title }}
              </h4>
              <b-card-text class="font-medium-1 mb-1">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  data() {
    return {
      items: [
        {
          // :src="require('@/assets/images/icons/pdf.png')"
          icon: require("@/assets/images/icons/JAULA.svg"),
          color: "light-info",
          title: "Jaula",
          subtitle: "PAR201 \n 10/01/2021",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: require("@/assets/images/icons/JAULA.svg"),
          color: "light-info",
          title: "Jaula",
          subtitle: "PAR203 \n 10/02/2021",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: require("@/assets/images/icons/JAULA.svg"),
          color: "light-info",
          title: "Jaula",
          subtitle: "PAR205 \n 10/03/2021",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: require("@/assets/images/icons/JAULA.svg"),
          color: "light-info",
          title: "Jaula",
          subtitle: "PAR207 \n 10/04/2021",
          customClass: "",
        },
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style>
.icono-jaula {
  filter: opacity(0.5);
}
</style>